
import React, { FC, useEffect } from "react";
import { Helmet } from "react-helmet";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import $ from 'jquery';
import logoff from "images/turn-off.png"

export interface CheckoutFailedProps {
  className?: string;
  statusText?: string;
  navigateHome?: () => void;
}

const CheckoutFailed: FC<CheckoutFailedProps> = ({ className = "", statusText, navigateHome }) => {
  
  return (
    <div
      className={`font nc-PageAbout overflow-hidden relative font xl:pt-24 xl:pb-32 lg:pt-24 lg:pb-32 md:pt-24 md:pb-24 pt-16 pb-32 ${className}`}
      data-nc-id="PageAbout"
    >
      <Helmet>
        <title>Travel Mart</title>
      </Helmet>

      {/* ======== BG GLASS ======== */}
      <BgGlassmorphism />

      <div className="font flex content-center items-center justify-center space-y-0  lg:space-y-0">
        <div className="w-12/12">
          <div className=" center">
            <img className="w-64 text-center center" src={logoff} alt="" />
          </div>
          <div className="text-center fonts xl:text-2xl  xl:pt-5 lg:text-4xl md:text-2xl text-xl lg:pt-4 md:pt-3 pt-3 xl:px-0 lg:px-0  md:px-0 px-4 text-red-600">
            {statusText}. Please try again
          </div>
          <br/>
          <div className="text-center">
            <button className="px-8 py-3 rounded-full bg-sky-600 text-white text-sm" type="button" onClick={navigateHome}>Back to Home</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CheckoutFailed;
