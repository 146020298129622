import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import SectionSliderNewCategories from "components/SectionSliderNewCategories/SectionSliderNewCategories";
import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";
import { TaxonomyType } from "data/types";
import React, { FC, Fragment, useEffect, useRef, useState } from "react";
import SectionGridFilterCard from "./SectionGridFilterCard";
import { Helmet } from "react-helmet";
import SectionHero2ArchivePage from "components/SectionHero2ArchivePage/SectionHero2ArchivePage";
import FilterSearchForm from "components/HeroSearchForm/FilterSearchForm";
import Pagination from "shared/Pagination/Pagination";
import PropertyCardH2 from "components/PropertyCardH/PropertyCardH2";
import axios from "axios";
import { Link, useParams } from "react-router-dom";
import Badge from "shared/Badge/Badge";
import convertNumbThousand from "utils/convertNumbThousand";
import NcImage from "shared/NcImage/NcImage";
import { Popover, Transition, Dialog } from "@headlessui/react";
import {
  CalendarIcon,
  ChevronDownIcon,
  ClockIcon,
  MapPinIcon,
} from "@heroicons/react/24/outline";
import GallerySlider from "components/GallerySlider/GallerySlider";
import ClearDataButton from "components/HeroSearchForm/ClearDataButton";
import StayDatesRangeInput from "components/HeroSearchForm2Mobile/DatesRangeInput";
import GuestsInput from "components/HeroSearchForm/GuestsInput";
import DatePickerCustomDay from "components/DatePickerCustomDay";
import DatePickerCustomHeaderTwoMonth from "components/DatePickerCustomHeaderTwoMonth";
import DatePicker from "react-datepicker";
import tmanim from "images/sample/tmanim.jpg";
import ButtonSubmit from "components/HeroSearchForm/ButtonSubmit";
import Skeleton from "react-loading-skeleton";

export interface ListingRealEstatePageProps {
  className?: string;
}

const ListingRealEstatePage: FC<ListingRealEstatePageProps> = ({
  className = "",
}) => {
  const { place, month } = useParams<{ place?: string; month?: string }>();

  const [locationText, setLocationText] = useState("");
  // const [selectedMonth, setSelectedMonth] = useState<string | null>('');

  useEffect(() => {
    const $body = document.querySelector("body");
    if ($body) {
      $body.className = "theme-cyan-blueGrey";
    }
    return () => {
      if ($body) {
        $body.className = "";
      }
    };
  }, []);

  const resetFilters = () => {
    setPriceFilters({
      lessThan10000: false,
      between10000and20000: false,
      between20000and40000: false,
      between40000and60000: false,
      between60000and80000: false,
      above80000: false,
    });

    setCategoryFilters({
      all: true,
      standard: false,
      luxury: false,
      premium: false,
      group: false,
    });

    setDayFilters({
      between1to3: false,
      between4to6: false,
      between7to9: false,
      between10to12: false,
      above13: false,
    });

    setFlightFilters({
      withFlight: false,
      withoutFlight: false,
    });
    filterPackagesUsingLocation();
    filterPackages();
  };

  interface PriceFilters {
    lessThan10000: boolean;
    between10000and20000: boolean;
    between20000and40000: boolean;
    between40000and60000: boolean;
    between60000and80000: boolean;
    above80000: boolean;
    [key: string]: boolean; // Index signature for dynamic keys
  }

  interface CategoryFilters {
    all: boolean;
    standard: boolean;
    luxury: boolean;
    premium: boolean;
    group: boolean;
    [key: string]: boolean; // Index signature for dynamic keys
  }

  interface DayFilters {
    between1to3: boolean;
    between4to6: boolean;
    between7to9: boolean;
    between10to12: boolean;
    above13: boolean;
    [key: string]: boolean; // Index signature for dynamic keys
  }

  interface FlightFilters {
    withFlight: boolean;
    withoutFlight: boolean;
    [key: string]: boolean; // Index signature for dynamic keys
  }

  const [issOpen, setIssOpen] = useState(false);
  const [onsOpen, setOnsOpen] = useState(true);
  const [onOpen, setOnOpen] = useState(true);
  const [isOpen, setIsOpen] = useState(true);
  const [inOpen, setInOpen] = useState(true);
  const [packages, setPackages] = useState([]);
  const [filteredPackages, setFilteredPackages] = useState<any[]>([]);
  const [locationFiltered, setLocationFiltered] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedItemId, setSelectedItemId] = useState(null);
  const [pricingData, setPricingData] = useState(null);

  function ircloseModal() {
    setSelectedItemId(null);
    setPricingData(null);
  }

  const iropenModal = async (itemId: any) => {
    await setSelectedItemId(itemId);
  };

  const containerRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const sidenavClass = issOpen ? "sidenav open" : "sidenav";

  const toggleSidenav = () => {
    setIssOpen(!issOpen);
  };

  const [showMoreStates, setShowMoreStates] = useState<{
    [key: number]: boolean;
  }>({});

  const toggleReadMoreLess = (index: number) => {
    setShowMoreStates((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  };

  const formatPrice = (price: string) => {
    // Convert the input string to a number
    const amount = parseFloat(price.replace(/,/g, ""));

    // Ensure amount is a valid number
    if (isNaN(amount)) {
      return "Invalid input";
    }

    // Use toLocaleString to format the number with Indian numbering system
    const formattedAmount = amount.toLocaleString("en-IN", {
      style: "currency",
      currency: "INR",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    });

    return formattedAmount;
  };

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 3000);
  }, []);

  const fetchPriceData = async (itemId: any) => {
    try {
      const response = await axios.get(
        "https://admin.trawelmart.com/admin/public/index.php/api/getPricingListByPackageForWebsite/" +
          itemId
      );
      if (response.status == 200) {
        const data = response.data;
        setPricingData(data);
      }
    } catch (error) {
      console.error("Error fetching additional data:", error);
      throw error; // You might want to handle the error appropriately in your application
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      if (selectedItemId !== null) {
        await fetchPriceData(selectedItemId);
      }
    };

    fetchData();
  }, [selectedItemId]);

  useEffect(() => {
    const placeValue = place ?? "";
    const monthValue = month ?? "";
    setLocationText(placeValue);
    setSelectedMonth(monthValue);
    axios
      .get(
        "https://admin.trawelmart.com/admin/public/index.php/api/getTourPackagesForWebsite"
      )
      .then((response) => {
        if (response.data.status) {
          setPackages(response.data.packages);
          setLocationFiltered(
            response.data.packages.filter(
              (pkg: any) =>
                (pkg as any).tour_city
                  .toLowerCase()
                  .includes(locationText.toLowerCase()) ||
                (pkg as any).tour_title
                  .toLowerCase()
                  .includes(locationText.toLowerCase())
            )
          );
          setFilteredPackages(
            response.data.packages.filter(
              (pkg: any) =>
                (pkg as any).tour_city
                  .toLowerCase()
                  .includes(locationText.toLowerCase()) ||
                (pkg as any).tour_title
                  .toLowerCase()
                  .includes(locationText.toLowerCase())
            )
          );
        }
      });
    resetFilters();
  }, []);

  useEffect(() => {
    setLocationText(place ?? "");
    setPlaceText(place ?? "");
  }, [place]);

  useEffect(() => {
    setSelectedMonth(month ?? "");
  }, [month]);

  const [showPopover, setShowPopover] = useState(false);
  const [priceFilters, setPriceFilters] = useState<PriceFilters>({
    lessThan10000: false,
    between10000and20000: false,
    between20000and40000: false,
    between40000and60000: false,
    between60000and80000: false,
    above80000: false,
  });

  const [categoryFilters, setCategoryFilters] = useState<CategoryFilters>({
    all: true,
    standard: false,
    luxury: false,
    premium: false,
    group: false,
  });

  const [dayFilters, setDayFilters] = useState<DayFilters>({
    between1to3: false,
    between4to6: false,
    between7to9: false,
    between10to12: false,
    above13: false,
  });

  const [flightFilters, setFlightFilters] = useState<FlightFilters>({
    withFlight: false,
    withoutFlight: false,
  });

  const handleSelectLocation = (item: string) => {
    setLocationText(item);
    setPlaceText(item);
    setShowPopover(false);
  };

  const handlePriceFilterChange = (filterName: string) => {
    setPriceFilters((prevFilters) => ({
      ...prevFilters,
      [filterName]: !prevFilters[filterName],
    }));
  };

  const handleCategoryFilterChange = (filterName: string) => {
    if (filterName === "all") {
      setCategoryFilters({
        all: true,
        standard: false,
        luxury: false,
        premium: false,
        group: false,
      });
    } else {
      setCategoryFilters((prevFilters) => ({
        ...prevFilters,
        ["all"]: false,
      }));
      setCategoryFilters((prevFilters) => ({
        ...prevFilters,
        [filterName]: !prevFilters[filterName],
      }));
    }
  };

  const handleDaysFilterChange = (filterName: string) => {
    setDayFilters((prevFilters) => ({
      ...prevFilters,
      [filterName]: !prevFilters[filterName],
    }));
  };

  const handleFlightsFilterChange = (filterName: string) => {
    setFlightFilters((prevFilters) => ({
      ...prevFilters,
      [filterName]: !prevFilters[filterName],
    }));
  };

  // const [startDate, setStartDate] = useState<Date | null>(new Date("2024/02/06"));
  // const [endDate, setEndDate] = useState<Date | null>(new Date("2024/02/23"));

  // const onChangeDate = (dates: [Date | null, Date | null]) => {
  //   const [start, end] = dates;
  //   setStartDate(start);
  //   setEndDate(end);
  // };

  const [startDate, setStartDate] = useState<Date | null>(
    new Date("2024/02/06")
  );
  const [endDate, setEndDate] = useState<Date | null>(new Date("2024/02/23"));
  const [selectedMonth, setSelectedMonth] = useState<string | null>(null);
  const [popoverVisible, setPopoverVisible] = useState(false);

  const onChangeDate = (dates: [Date | null, Date | null]) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };

  const renderInput = () => {
    return (
      <>
        <div className="text-neutral-300 dark:text-neutral-400">
          <CalendarIcon className="w-5 h-5 lg:w-7 lg:h-7" />
        </div>
        <div className="flex-grow text-left">
          <span className="block text-lg font-semibold">
            {selectedMonth
              ? `${selectedMonth} ${startDate?.getFullYear() || ""}`
              : "Travel Date"}
          </span>
          {/* <span className="block mt-1 text-sm text-neutral-400 font-light  leading-none">
            {"Select Month"}
          </span> */}
          {!selectedMonth && (
            <span className="block mt-1 text-sm text-neutral-400 font-light leading-none">
              {"Select Month"}
            </span>
          )}
        </div>
      </>
    );
  };

  // const handleMonthClick = (month: string) => {
  //   setSelectedMonth(month);
  // };

  const handleMonthClick = (month: string) => {
    setSelectedMonth(month);
    setPopoverVisible(false);
  };

  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const [placeText, setPlaceText] = useState("");

  function filterPackagesUsingLocation() {
    console.log("Location is " + locationText);

    const locationFiltered1 = packages.filter(
      (pkg) =>
        (pkg as any).tour_city
          .toLowerCase()
          .includes(locationText.toLowerCase()) ||
        (pkg as any).tour_title
          .toLowerCase()
          .includes(locationText.toLowerCase())
    );

    console.log("Location filtered : " + locationFiltered1);

    setLocationFiltered(locationFiltered1);
    setFilteredPackages(locationFiltered1);
    // if(placeValue !== '')
    // {
    //   locationFiltered = locationFiltered.filter(pkg =>
    //     (pkg as any).tour_city.toLowerCase().includes(placeValue.toLowerCase()) ||
    //     (pkg as any).tour_title.toLowerCase().includes(placeValue.toLowerCase())
    //   );
    // }
    // Use the filter method to get packages that match the entered location text
  }

  function filterPackages() {
    let filteredPackages1: typeof locationFiltered = locationFiltered;
    if (Object.values(priceFilters).some(Boolean)) {
      // Apply price filter only if any price filter is selected
      const priceFiltered = locationFiltered.filter((pkg2) => {
        const packagePrice = (pkg2 as any).price;

        return (
          (priceFilters.lessThan10000 && packagePrice <= 10000) ||
          (priceFilters.between10000and20000 &&
            packagePrice > 10000 &&
            packagePrice <= 20000) ||
          (priceFilters.between20000and40000 &&
            packagePrice > 20000 &&
            packagePrice <= 40000) ||
          (priceFilters.between40000and60000 &&
            packagePrice > 40000 &&
            packagePrice <= 60000) ||
          (priceFilters.between60000and80000 &&
            packagePrice > 60000 &&
            packagePrice <= 80000) ||
          (priceFilters.above80000 && packagePrice > 80000)
        );
      });

      filteredPackages1 = priceFiltered;
    }

    if (Object.values(categoryFilters).some(Boolean)) {
      // Apply category filter only if any category filter is selected
      const categoryFiltered = filteredPackages1.filter((pkg3) => {
        const packageCategory = (pkg3 as any).tour_type;

        console.log("Category:", packageCategory, "Filters:", categoryFilters);

        if (categoryFilters.all) {
          // Include all categories if categoryFilters.all is true
          return true;
        } else {
          // Apply specific category filters
          return (
            (categoryFilters.standard && packageCategory === "Standard") ||
            (categoryFilters.premium && packageCategory === "Premium") ||
            (categoryFilters.luxury && packageCategory === "Luxury") ||
            (categoryFilters.group && packageCategory === "Group")
          );
        }
      });

      console.log("Category Filtered:", categoryFiltered);

      filteredPackages1 = categoryFiltered;
    }

    if (Object.values(dayFilters).some(Boolean)) {
      // Apply price filter only if any price filter is selected
      const dayFiltered = filteredPackages1.filter((pkg4) => {
        const packageDays = (pkg4 as any).tour_days;

        return (
          (dayFilters.between1to3 && packageDays > 1 && packageDays <= 3) ||
          (dayFilters.between4to6 && packageDays > 4 && packageDays <= 6) ||
          (dayFilters.between7to9 && packageDays > 7 && packageDays <= 9) ||
          (dayFilters.between10to12 && packageDays > 10 && packageDays <= 12) ||
          (dayFilters.above13 && packageDays > 13)
        );
      });

      filteredPackages1 = dayFiltered;
    }

    if (Object.values(flightFilters).some(Boolean)) {
      // Apply price filter only if any price filter is selected
      const flightFiltered = filteredPackages1.filter((pkg5) => {
        const packageFlight = (pkg5 as any).is_flight;

        return (
          (flightFilters.withFlight && packageFlight === "Yes") ||
          (flightFilters.withoutFlight && packageFlight === "No")
        );
      });

      filteredPackages1 = flightFiltered;
    }

    console.log("Final Filtered Packages:", filteredPackages1);

    setFilteredPackages(filteredPackages1);
  }

  useEffect(() => {
    if (eventClickOutsideDiv) {
      document.removeEventListener("click", eventClickOutsideDiv);
    }
    showPopover && document.addEventListener("click", eventClickOutsideDiv);
    return () => {
      document.removeEventListener("click", eventClickOutsideDiv);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showPopover]);

  const eventClickOutsideDiv = (event: MouseEvent) => {
    if (!containerRef.current) return;
    // CLICK IN_SIDE
    if (!showPopover || containerRef.current.contains(event.target as Node)) {
      return;
    }
    // CLICK OUT_SIDE
    setShowPopover(false);
  };

  useEffect(() => {
    resetFilters();
  }, [locationText]);

  useEffect(() => {
    setIsLoading(true);
    filterPackagesUsingLocation();
    filterPackages();
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }, [priceFilters, categoryFilters, dayFilters, flightFilters]);

  return (
    <div
      className={`nc-ListingRealEstatePage relative ${className}`}
      data-nc-id="ListingRealEstatePage"
    >
      <Helmet>
        <title>Trawel Mart</title>
      </Helmet>

      <div className="container relative">
        {/* SECTION HERO*/}
        <ul className="breadcrumb">
          <li>
            <a href="/">Home</a>
          </li>
          <li>Filter</li>
        </ul>
      </div>

      {/* SECTION */}
      {filteredPackages.length > 0 &&
      packages.filter(
        (pkg) =>
          (pkg as any).tour_city
            .toLowerCase()
            .includes(locationText.toLowerCase()) ||
          (pkg as any).tour_title
            .toLowerCase()
            .includes(locationText.toLowerCase())
      ).length > 0 ? (
        <>
          <div className="bg-white relative pt-6">
            <div className="container relative xl:block lg:block hidden ">
              <div
                className={`bg-font nc-HeroSearchForm w-full max-w-7xl py-5 lg:py-0 ${className}`}
              >
                {/*renderTab()*/}
                <form className="w-full relative lg:flex md:flex mt-0 ">
                  <div className="xl:w-7/12 md:w-7/12 h-20 border border-neutral-300 rounded-xl mr-2">
                    <div
                      className={`relative flex lg:-mt-2 mx-2`}
                      ref={containerRef}
                    >
                      <div
                        onClick={() => setShowPopover(true)}
                        className={`flex z-10 flex-1 relative [ nc-hero-field-padding ] flex-shrink-0 items-center xl:pt-8 space-x-3 cursor-pointer focus:outline-none text-left  ${
                          showPopover ? "" : ""
                        }`} /*nc-hero-field-focused =====${showPopover ? "" inside the "" */
                      >
                        <div className="text-neutral-300 dark:text-neutral-400">
                          <MapPinIcon className="w-5 h-5 lg:w-7 lg:h-7" />
                        </div>
                        <div className="flex-grow">
                          <input
                            className={`block w-full bg-transparent border-none focus:ring-0 p-0 focus:outline-none focus:placeholder-neutral-300 xl:text-lg font-semibold placeholder-neutral-800 dark:placeholder-neutral-200 truncate`}
                            placeholder="Destinations"
                            value={placeText}
                            autoFocus={showPopover}
                            onChange={(e) => {
                              setPlaceText(e.currentTarget.value);
                            }}
                            ref={inputRef}
                          />
                          {/* <span className="block mt-0.5 text-sm text-neutral-400 font-light ">
                            <span className="line-clamp-1">
                              {!!locationText
                                ? "Destinations"
                                : "Where do you want to travel?"}
                            </span>
                          </span> */}
                          {locationText && showPopover && (
                            <ClearDataButton
                              onClick={() => {
                                setPlaceText("");
                                (window as any).location = "/filter-details/";
                              }}
                            />
                          )}
                        </div>
                      </div>

                      {showPopover && (
                        <div
                          className={`h-8 absolute self-center top-1/2 -translate-y-1/2 z-0 bg-white dark:bg-neutral-800 left-10 -right-0.5`}
                        ></div>
                      )}

                      {showPopover && (
                        <div className="absolute left-0 z-40 w-full min-w-[300px] sm:min-w-[500px] bg-white dark:bg-neutral-800 top-full mt-3 py-3 sm:py-6 rounded-3xl shadow-xl max-h-96 overflow-y-auto">
                          {locationText ? (
                            <>
                              {["Dubai", "Bali", "Singapore", "Vietnam"].map(
                                (item) => (
                                  <span
                                    onClick={() => handleSelectLocation(item)}
                                    key={item}
                                    className="flex px-4 sm:px-8 items-center space-x-3 sm:space-x-4 py-4 hover:bg-neutral-100 dark:hover:bg-neutral-700 cursor-pointer"
                                  >
                                    <span className="block text-neutral-400">
                                      <ClockIcon className="h-4 w-4 sm:h-6 sm:w-6" />
                                    </span>
                                    <span className="block font-medium text-neutral-700 dark:text-neutral-200">
                                      {item}
                                    </span>
                                  </span>
                                )
                              )}
                            </>
                          ) : (
                            <>
                              <h3 className="font block mt-2 sm:mt-0 px-4 sm:px-8 font-semibold text-base sm:text-lg text-neutral-800 dark:text-neutral-100">
                                Recent searches
                              </h3>
                              <div className="mt-2">
                                {["Dubai", "Bali", "Singapore", "Vietnam"].map(
                                  (item) => (
                                    <span
                                      onClick={() => handleSelectLocation(item)}
                                      key={item}
                                      className="flex px-4 sm:px-8 items-center space-x-3 sm:space-x-4 py-4 hover:bg-neutral-100 dark:hover:bg-neutral-700 cursor-pointer"
                                    >
                                      <span className="block text-neutral-400">
                                        <ClockIcon className="h-4 sm:h-6 w-4 sm:w-6" />
                                      </span>
                                      <span className=" block font-medium text-neutral-700 dark:text-neutral-200">
                                        {item}
                                      </span>
                                    </span>
                                  )
                                )}
                              </div>
                            </>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="self-center  h-8"></div>
                  <div className="xl:w-5/12 md:w-5/12 h-20 border border-neutral-300 rounded-xl mr-2 xl:-mt-0 lg:-mt-0 md:-mt-0 -mt-6">
                    <Popover
                      className={`StayDatesRangeInput z-10 relative flex`}
                    >
                      {({ open, close }) => (
                        <>
                          <Popover.Button
                            className={`z-10 flex-1 flex justify-between relative xl:pl-4 items-center space-x-3 focus:outline-none  ${
                              open ? "" : ""
                            }`}
                            onClickCapture={() => {
                              document.querySelector("html")?.click();
                              setPopoverVisible(!popoverVisible); // Toggle the visibility of the Popover
                            }}
                          >
                            {renderInput()}
                            {startDate && open && (
                              <ClearDataButton
                                onClick={() => onChangeDate([null, null])}
                              />
                            )}
                          </Popover.Button>

                          {/* BUTTON SUBMIT OF FORM */}
                          <div className="pr-2 xl:pl-28 py-4">
                            <ButtonSubmit
                              href={
                                placeText === ""
                                  ? "#!"
                                  : "/filter-details/" +
                                    placeText +
                                    "/" +
                                    (selectedMonth === null ||
                                    selectedMonth === ""
                                      ? ""
                                      : selectedMonth)
                              }
                            />
                          </div>

                          {open && (
                            <div className="h-8 absolute self-center top-1/2 -translate-y-1/2 z-0 -inset-x-0.5  dark:bg-neutral-800"></div>
                          )}

                          {/* <Transition
                            as={Fragment}
                            enter="transition ease-out duration-200"
                            enterFrom="opacity-0 translate-y-1"
                            enterTo="opacity-100 translate-y-0"
                            leave="transition ease-in duration-150"
                            leaveFrom="opacity-100 translate-y-0"
                            leaveTo="opacity-0 translate-y-1"
                          >
                            <Popover.Panel className="absolute left-1/2 z-10 mt-3 top-full w-screen max-w-sm -translate-x-1/2 transform px-4 sm:px-0 lg:max-w-sm">
                              <div className="overflow-hidden rounded-xl shadow-lg ring-1 ring-black ring-opacity-5 bg-white dark:bg-neutral-800">
                                <div className="flex-grow text-left">
                                  <span className="block xl:text-lg font-semibold">
                                    {selectedMonth || "Travel Date"}
                                  </span>
                                  <span className="block mt-1 text-sm text-neutral-600 leading-none font-semibold uppercase text-center py-4">
                                    {"Select Month"}
                                  </span>
                                </div>
                                <div className="grid grid-cols-4 gap-0 border-t">
                                  {months.map((month, index) => (
                                    <div key={index} className="cursor-pointer hover:bg-neutral-100 border p-3 border-neutral-100 text-center text-base" onClick={() => handleMonthClick(month)}>
                                      <h3 className="font-medium font">{month.substr(0, 3)}</h3>
                                      <p className="text-neutral-500 font1 text-base font-semibold">2024</p>
                                    </div>
                                  ))}
                                </div>
                              </div>
                            </Popover.Panel>
                          </Transition> */}

                          <Transition
                            as={Fragment}
                            enter="transition ease-out duration-200"
                            enterFrom="opacity-0 translate-y-1"
                            enterTo="opacity-100 translate-y-0"
                            leave="transition ease-in duration-150"
                            leaveFrom="opacity-100 translate-y-0"
                            leaveTo="opacity-0 translate-y-1"
                          >
                            <Popover.Panel className="absolute left-1/2 z-10 mt-3 top-full w-screen max-w-sm -translate-x-1/2 transform px-4 sm:px-0 lg:max-w-sm">
                              {popoverVisible && ( // Conditionally render the popover based on visibility state
                                <div className="overflow-hidden rounded-xl shadow-lg ring-1 ring-black ring-opacity-5 bg-white dark:bg-neutral-800">
                                  <div className="flex-grow text-left">
                                    <span className="block mt-1 text-sm text-neutral-600 leading-none font-semibold uppercase text-center py-4">
                                      {"Select Month"}
                                    </span>
                                  </div>
                                  <div className="grid grid-cols-4 gap-0 border-t">
                                    {months.map((month, index) => (
                                      <div
                                        key={index}
                                        className="cursor-pointer hover:bg-neutral-100 border p-3 border-neutral-100 text-center text-base"
                                        onClick={() => {
                                          handleMonthClick(month);
                                          close(); // Close the Popover after selecting the month
                                        }}
                                      >
                                        <h3 className="font-medium font">
                                          {month.substr(0, 3)}
                                        </h3>
                                        <p className="text-neutral-500 font1 text-base font-semibold">
                                          2024
                                        </p>
                                      </div>
                                    ))}
                                  </div>
                                </div>
                              )}
                            </Popover.Panel>
                          </Transition>
                        </>
                      )}
                    </Popover>
                    <div className="self-center  border-slate-200 dark:border-slate-700 h-8"></div>

                    {/* <StayDatesRangeInput className=" mx-1" buttonSubmitHref={"/filter-details/" + locationText}/> */}
                    {/* <Popover className={`StayDatesRangeInput z-10 relative flex lg:-mt-2 mx-1 [ lg:nc-flex-2 ]`}>
                      {({ open }) => (
                        <>
                          <Popover.Button
                            className={` z-10 flex relative [ nc-hero-field-padding ] items-center space-x-3 focus:outline-none ${
                              open ? "" : ""
                            }`}
                            onClickCapture={() => document.querySelector("html")?.click()}
                          >
                            {renderInput()}
                            {startDate && open && (
                              <ClearDataButton onClick={() => onChangeDate([null, null])} />
                            )}
                          </Popover.Button>
                            
                          {open && (
                            <div className="h-8 absolute self-center top-1/2 -translate-y-1/2 z-0 -inset-x-0.5 bg-white dark:bg-neutral-800"></div>
                          )}

                          <Transition
                            as={Fragment}
                            enter="transition ease-out duration-200"
                            enterFrom="opacity-0 translate-y-1"
                            enterTo="opacity-100 translate-y-0"
                            leave="transition ease-in duration-150"
                            leaveFrom="opacity-100 translate-y-0"
                            leaveTo="opacity-0 translate-y-1"
                          >
                            <Popover.Panel className="absolute left-1/2 z-10 mt-3 top-full w-screen max-w-sm -translate-x-1/2 transform px-4 sm:px-0 lg:max-w-3xl">
                              <div className="overflow-hidden rounded-3xl shadow-lg ring-1 ring-black ring-opacity-5 bg-white dark:bg-neutral-800 p-8">
                                <DatePicker
                                  selected={startDate}
                                  onChange={onChangeDate}
                                  startDate={startDate}
                                  endDate={endDate}
                                  selectsRange
                                  monthsShown={2}
                                  showPopperArrow={false}
                                  inline
                                  renderCustomHeader={(p) => (
                                    <DatePickerCustomHeaderTwoMonth {...p} />
                                  )}
                                  renderDayContents={(day, date) => (
                                    <DatePickerCustomDay dayOfMonth={day} date={date} />
                                  )}
                                />
                              </div>
                            </Popover.Panel>
                          </Transition>
                        </>
                      )}
                    </Popover> */}
                  </div>
                  {/* <div className="self-center  h-8"></div>
                  <div className="xl:w-4/12 md:w-5/12 h-20 border border-neutral-300 rounded-xl xl:-mt-0 lg:-mt-0 md:-mt-0 -mt-6">
                      <GuestsInput className="flex-1 lg:-mt-2 mx-2" buttonSubmitHref={"/filter-details/" + locationText}/>
                  </div> */}
                </form>
              </div>
            </div>
          </div>
          <div className="container relative">
            <div
              className={`font relative nc-SectionGridFilterCard md:pt-4 py-24 lg:pb-12 lg:pt-0`}
              data-nc-id="SectionGridFilterCard"
            >
              {/* desktop filter details */}
              <div className="w-full relative lg:pt-6">
                <div className="xl:flex lg:flex">
                  <div className="xl:w-3/12 lg:w-4/12 xl:block lg:block md:hidden hidden">
                    <div className="sticky top-28">
                      <div className="p-4 rounded-lg  border border-neutral-200 shadow-xl">
                        {/*  <div className="mb-8 lg:mb-6 md:mb-2">
                        <TabFilters2 />
                         </div>*/}
                        <div className="flex justify-between">
                          <div className="text-base font-semibold pb-2">
                            Filter Your Tours
                          </div>
                          <div className="text-green-700 text-sm pb-2">
                            <a href="#!" onClick={resetFilters}>
                              Clear
                            </a>
                          </div>
                        </div>

                        {/* sixth filter */}
                        {/* <div className="border-t"></div>
                       <div
                      className={`font  relative lg:pt-6 lg:pb-4`}>
                                {/*  eslint-disable-next-line jsx-a11y/anchor-has-content */}
                        {/*<a href="##" className="absolute inset-0" />
                                <span
                                  className={`absolute right-0 bottom-0 sm:bottom-auto items-center lg:mt-2  sm:-translate-y-1/2 w-10 h-10 flex items-center justify-center cursor-pointer ${itOpen ? "transform -rotate-180" : ""
                                   }`}
                                  onClick={() => setItOpen(!itOpen)}
                               >
                                  <i className="text-xl las la-angle-down"></i>
                                </span>
                                <div className="flex flex-col sm:flex-row sm:items-center space-y-6 sm:space-y-0">
                                 <div className="font-medium text-xl md:text-lg xl:text-base !leading-[114%] lg:pb-2 lg:pt-0 lg:pb-0 md:pb-4 md:pt-4">
                                   Price Range
                                  </div>
                                </div>
                                {renderDetail6()}
                             </div> */}

                        {/* Fourth filter */}
                        <div className="border-t"></div>
                        <div className={`font  relative lg:pt-8 lg:pb-4`}>
                          {/*  eslint-disable-next-line jsx-a11y/anchor-has-content */}
                          {/*<a href="##" className="absolute inset-0" />*/}
                          <span
                            className={`absolute right-0 bottom-0 sm:bottom-auto items-center lg:mt-2  sm:-translate-y-1/2 w-10 h-10 flex items-center justify-center cursor-pointer ${
                              inOpen ? "transform -rotate-180" : ""
                            }`}
                            onClick={() => setInOpen(!inOpen)}
                          >
                            <i className="text-xl las la-angle-down"></i>
                          </span>
                          <div className="flex flex-col sm:flex-row sm:items-center space-y-6 sm:space-y-0">
                            <div className="font-medium text-xl md:text-lg xl:text-base !leading-[114%] lg:pb-2 lg:pt-0 lg:pb-0 md:pb-4 md:pt-4">
                              Budget Per Person (in Rs.)
                            </div>
                          </div>
                          {inOpen && (
                            <div className="">
                              <div className="my-1 md:my-1 space-y-5 md:pl-2">
                                <div className="pb-3 pt-2">
                                  {/* <div className="flex items-center">
                                  <input type="checkbox" name="price_filter" checked={priceFilters.lessThan10000} onChange={() => handlePriceFilterChange('lessThan10000')} className="border border-neutral-400 w-5 h-5 mb-3" />
                                  <label className="xl:text-sm text-base pl-2 mb-2"> Less Than 10,000</label><br />
                                </div> */}
                                  <div className="flex items-center pt-2">
                                    <input
                                      type="checkbox"
                                      name="price_filter"
                                      checked={
                                        priceFilters.between10000and20000
                                      }
                                      onChange={() =>
                                        handlePriceFilterChange(
                                          "between10000and20000"
                                        )
                                      }
                                      className="border border-neutral-400 w-5 h-5 mb-3"
                                    />
                                    <label className="xl:text-sm text-base pl-2 mb-2">
                                      {" "}
                                      10,000 - 20,000{" "}
                                    </label>
                                    <br />
                                  </div>

                                  <div className="flex items-center pt-2">
                                    <input
                                      type="checkbox"
                                      name="price_filter"
                                      checked={
                                        priceFilters.between20000and40000
                                      }
                                      onChange={() =>
                                        handlePriceFilterChange(
                                          "between20000and40000"
                                        )
                                      }
                                      className="border border-neutral-400 w-5 h-5 mb-3"
                                    />
                                    <label className="xl:text-sm text-base pl-2 mb-2">
                                      {" "}
                                      20,000 - 40,000
                                    </label>
                                    <br />
                                  </div>
                                  <div className="flex items-center pt-2">
                                    <input
                                      type="checkbox"
                                      name="price_filter"
                                      checked={
                                        priceFilters.between40000and60000
                                      }
                                      onChange={() =>
                                        handlePriceFilterChange(
                                          "between40000and60000"
                                        )
                                      }
                                      className="border border-neutral-400 w-5 h-5 mb-3"
                                    />
                                    <label className="xl:text-sm text-base pl-2 mb-2">
                                      {" "}
                                      40,000 - 60,000
                                    </label>
                                    <br />
                                  </div>

                                  <div className="flex items-center pt-2">
                                    <input
                                      type="checkbox"
                                      name="price_filter"
                                      checked={
                                        priceFilters.between60000and80000
                                      }
                                      onChange={() =>
                                        handlePriceFilterChange(
                                          "between60000and80000"
                                        )
                                      }
                                      className="border border-neutral-400 w-5 h-5 mb-3"
                                    />
                                    <label className="xl:text-sm text-base pl-2 mb-2">
                                      {" "}
                                      60,000 - 80,000
                                    </label>
                                    <br />
                                  </div>

                                  <div className="flex items-center pt-2">
                                    <input
                                      type="checkbox"
                                      name="price_filter"
                                      checked={priceFilters.above80000}
                                      onChange={() =>
                                        handlePriceFilterChange("above80000")
                                      }
                                      className="border border-neutral-400 w-5 h-5 mb-3"
                                    />
                                    <label className="xl:text-sm text-base pl-2 mb-2">
                                      {" "}
                                      Above 80,000
                                    </label>
                                    <br />
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>

                        <div className="border-t"></div>

                        {/* First filter */}
                        <div className={`font  relative lg:pt-6 lg:pb-4 `}>
                          <span
                            className={`absolute right-0 bottom-0 sm:bottom-auto items-center lg:mt-4 sm:-translate-y-1/2 w-10 h-10 flex items-center justify-center cursor-pointer ${
                              isOpen ? "transform -rotate-180" : ""
                            }`}
                            onClick={() => setIsOpen(!isOpen)}
                          >
                            <i className="text-xl las la-angle-down"></i>
                          </span>
                          <div className="flex  flex-col sm:flex-row sm:items-center space-y-6 sm:space-y-0">
                            <div className="font-medium text-xl md:text-lg xl:text-base !leading-[114%] lg:pb-2 lg:pt-0 lg:pb-0 md:pb-4 md:pt-4">
                              Categories
                            </div>
                          </div>
                          {isOpen && (
                            <div className="">
                              <div className="my-1 md:my-1 space-y-5">
                                <div className="">
                                  <div className="pb-3 pt-2">
                                    <div className="grid grid-cols-2">
                                      <div className="flex items-center">
                                        <input
                                          type="checkbox"
                                          name="category_filter"
                                          checked={categoryFilters.all}
                                          onChange={() =>
                                            handleCategoryFilterChange("all")
                                          }
                                          className="border border-neutral-400 w-5 h-5 mb-3"
                                        />
                                        <label className="xl:text-sm text-base pl-2 mb-2">
                                          All
                                        </label>
                                        <br />
                                      </div>
                                      <div className="flex items-center">
                                        <input
                                          type="checkbox"
                                          name="category_filter"
                                          checked={categoryFilters.standard}
                                          onChange={() =>
                                            handleCategoryFilterChange(
                                              "standard"
                                            )
                                          }
                                          className="border border-neutral-400 w-5 h-5 mb-3"
                                        />
                                        <label className="xl:text-sm text-base pl-2 mb-2">
                                          {" "}
                                          Standard
                                        </label>
                                        <br />
                                      </div>
                                      <div className="flex items-center">
                                        <input
                                          type="checkbox"
                                          name="category_filter"
                                          checked={categoryFilters.luxury}
                                          onChange={() =>
                                            handleCategoryFilterChange("luxury")
                                          }
                                          className="border border-neutral-400 w-5 h-5 mb-3"
                                        />
                                        <label className="xl:text-sm text-base pl-2 mb-2">
                                          {" "}
                                          Luxury{" "}
                                        </label>
                                        <br />
                                      </div>
                                    </div>

                                    <div className="grid grid-cols-2 pt-2">
                                      <div className="flex items-center">
                                        <input
                                          type="checkbox"
                                          name="category_filter"
                                          checked={categoryFilters.premium}
                                          onChange={() =>
                                            handleCategoryFilterChange(
                                              "premium"
                                            )
                                          }
                                          className="border border-neutral-400 w-5 h-5 mb-3"
                                        />
                                        <label className="xl:text-sm text-base pl-2 mb-2">
                                          {" "}
                                          Premium{" "}
                                        </label>
                                        <br />
                                      </div>
                                      <div className="flex items-center">
                                        <input
                                          type="checkbox"
                                          name="category_filter"
                                          checked={categoryFilters.group}
                                          onChange={() =>
                                            handleCategoryFilterChange("group")
                                          }
                                          className="border border-neutral-400 w-5 h-5 mb-3"
                                        />
                                        <label className="xl:text-sm text-base pl-2 mb-2">
                                          {" "}
                                          Group Tour{" "}
                                        </label>
                                        <br />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>

                        {/* Second filter */}
                        <div className="border-t"></div>
                        <div className={`font  relative lg:pt-6 lg:pb-4`}>
                          {/*  eslint-disable-next-line jsx-a11y/anchor-has-content */}
                          {/*<a href="##" className="absolute inset-0" />*/}
                          <span
                            className={`absolute right-0 bottom-0 sm:bottom-auto items-center lg:mt-2 sm:-translate-y-1/2 w-10 h-10 flex items-center justify-center cursor-pointer ${
                              onOpen ? "transform -rotate-180" : ""
                            }`}
                            onClick={() => setOnOpen(!onOpen)}
                          >
                            <i className="text-xl las la-angle-down"></i>
                          </span>
                          <div className="flex  flex-col sm:flex-row sm:items-center space-y-6 sm:space-y-0">
                            <div className="font-medium text-xl md:text-lg xl:text-base !leading-[114%] lg:pb-2 lg:pt-0 lg:pb-0 md:pb-4 md:pt-4">
                              Duration (in Days)
                            </div>
                          </div>
                          {onOpen && (
                            <div className="">
                              {/* {renderDetailTop()} */}
                              <div className="my-1 md:my-1 space-y-5 md:pl-2">
                                <div className="pb-3 pt-2">
                                  <div className="grid grid-cols-2">
                                    <div className="flex items-center">
                                      <input
                                        type="checkbox"
                                        name="days_filter"
                                        checked={dayFilters.between1to3}
                                        onChange={() =>
                                          handleDaysFilterChange("between1to3")
                                        }
                                        className="border border-neutral-400 w-5 h-5 mb-3"
                                      />
                                      <label className="xl:text-sm text-base pl-2 mb-2">
                                        {" "}
                                        1 to 3
                                      </label>
                                      <br />
                                    </div>
                                    <div className="flex items-center">
                                      <input
                                        type="checkbox"
                                        name="days_filter"
                                        checked={dayFilters.between4to6}
                                        onChange={() =>
                                          handleDaysFilterChange("between4to6")
                                        }
                                        className="border border-neutral-400 w-5 h-5 mb-3"
                                      />
                                      <label className="xl:text-sm text-base pl-2 mb-2">
                                        {" "}
                                        4 to 6
                                      </label>
                                      <br />
                                    </div>
                                  </div>

                                  <div className="grid grid-cols-2 pt-2">
                                    <div className="flex items-center">
                                      <input
                                        type="checkbox"
                                        name="days_filter"
                                        checked={dayFilters.between7to9}
                                        onChange={() =>
                                          handleDaysFilterChange("between7to9")
                                        }
                                        className="border border-neutral-400 w-5 h-5 mb-3"
                                      />
                                      <label className="xl:text-sm text-base pl-2 mb-2">
                                        {" "}
                                        7 to 9
                                      </label>
                                      <br />
                                    </div>
                                    <div className="flex items-center">
                                      <input
                                        type="checkbox"
                                        name="days_filter"
                                        checked={dayFilters.between10to12}
                                        onChange={() =>
                                          handleDaysFilterChange(
                                            "between10to12"
                                          )
                                        }
                                        className="border border-neutral-400 w-5 h-5 mb-3"
                                      />
                                      <label className="xl:text-sm text-base pl-2 mb-2">
                                        {" "}
                                        10 to 12
                                      </label>
                                      <br />
                                    </div>
                                  </div>

                                  <div className="flex items-center pt-2">
                                    <input
                                      type="checkbox"
                                      name="days_filter"
                                      checked={dayFilters.above13}
                                      onChange={() =>
                                        handleDaysFilterChange("above13")
                                      }
                                      className="border border-neutral-400 w-5 h-5 mb-3"
                                    />
                                    <label className="xl:text-sm text-base pl-2 mb-2">
                                      {" "}
                                      13 or more
                                    </label>
                                    <br />
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                        {/* Third filter */}
                        <div className="border-t"></div>
                        <div className={`font  relative lg:pt-6 lg:pb-4`}>
                          {/*  eslint-disable-next-line jsx-a11y/anchor-has-content */}
                          {/*<a href="##" className="absolute inset-0" />*/}
                          <span
                            className={`absolute right-0 bottom-0 sm:bottom-auto items-center lg:mt-2  sm:-translate-y-1/2 w-10 h-10 flex items-center justify-center cursor-pointer ${
                              onsOpen ? "transform -rotate-180" : ""
                            }`}
                            onClick={() => setOnsOpen(!onsOpen)}
                          >
                            <i className="text-xl las la-angle-down"></i>
                          </span>
                          <div className="flex flex-col sm:flex-row sm:items-center space-y-6 sm:space-y-0">
                            <div className="font-medium text-xl md:text-lg xl:text-base !leading-[114%] lg:pb-2 lg:pt-0 lg:pb-0 md:pb-4 md:pt-4">
                              Flight Details
                            </div>
                          </div>
                          {onsOpen && (
                            <div className="">
                              {/* {renderDetailTop()} */}
                              <div className="my-1 md:my-1 space-y-5 md:pl-2">
                                <div className="pb-3 pt-2">
                                  <div className="flex items-center">
                                    <input
                                      type="checkbox"
                                      name="flight_details"
                                      checked={flightFilters.withFlight}
                                      onChange={() =>
                                        handleFlightsFilterChange("withFlight")
                                      }
                                      className="border border-neutral-400 w-5 h-5 mb-3"
                                    />
                                    <label className="xl:text-sm text-base pl-2 mb-2">
                                      {" "}
                                      With Flight
                                    </label>
                                    <br />
                                  </div>
                                  <div className="flex items-center">
                                    <input
                                      type="checkbox"
                                      name="flight_details"
                                      checked={flightFilters.withoutFlight}
                                      onChange={() =>
                                        handleFlightsFilterChange(
                                          "withoutFlight"
                                        )
                                      }
                                      className="border border-neutral-400 w-5 h-5 mb-3"
                                    />
                                    <label className="xl:text-sm text-base pl-2 mb-2">
                                      {" "}
                                      Without Flight
                                    </label>
                                    <br />
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* ===================== MOBILE AND TAB VIEW FILTER SLIDER ============== */}

                  {/* Second Col filter section starts */}

                  <div className="xl:w-9/12 md:12/12 xl:pl-12 lg:w-10/12 lg:pl-4 xl:pt-0 lg:pt-0 md:pt-0 xl:-mt-0 lg:-mt-0 md:-mt-12 -mt-32">
                    {/* tab view filter details */}
                    <div className="xl:hidden lg:hidden md:block block sm:block">
                      <div id="mySidenav" className={sidenavClass}>
                        <a
                          href="javascript:void(0)"
                          className="closebtn"
                          onClick={toggleSidenav}
                        >
                          &times;
                        </a>
                        <a>
                          <div
                            className={`font  relative lg:pt-6 lg:pb-4 pt-6 pb-4`}
                          >
                            {/*  eslint-disable-next-line jsx-a11y/anchor-has-content */}
                            {/*<a href="##" className="absolute inset-0" />*/}
                            <span
                              className={`absolute right-0 bottom-0 top-0 sm:bottom-auto items-center lg:mt-2 md:mt-6 mt-4 sm:-translate-y-1/2 w-10 h-10 flex items-center justify-center cursor-pointer ${
                                inOpen ? "transform -rotate-180" : ""
                              }`}
                              onClick={() => setInOpen(!inOpen)}
                            >
                              <i className="text-xl las la-angle-down"></i>
                            </span>
                            <div className="flex flex-col sm:flex-row sm:items-center space-y-6 sm:space-y-0">
                              <div className="font-medium text-xl md:text-lg xl:text-base !leading-[114%] lg:pb-2 lg:pt-0 lg:pb-0 md:pb-4 md:pt-4">
                                Budget Per Person (in Rs.)
                              </div>
                            </div>
                            {inOpen && (
                              <div className="">
                                <div className="my-1 md:my-1 space-y-5 md:pl-2">
                                  <div className="pb-3 pt-2">
                                    {/* <div className="flex items-center">
                                      <input type="checkbox" name="price_filter" checked={priceFilters.lessThan10000} onChange={() => handlePriceFilterChange('lessThan10000')} className="border border-neutral-400 w-5 h-5 mb-3" />
                                      <label className="xl:text-sm text-base pl-2 mb-2"> Less Than 10,000</label><br />
                                    </div> */}
                                    <div className="flex items-center pt-2">
                                      <input
                                        type="checkbox"
                                        name="price_filter"
                                        checked={
                                          priceFilters.between10000and20000
                                        }
                                        onChange={() =>
                                          handlePriceFilterChange(
                                            "between10000and20000"
                                          )
                                        }
                                        className="border border-neutral-400 w-5 h-5 mb-3"
                                      />
                                      <label className="xl:text-sm text-base pl-2 mb-2">
                                        {" "}
                                        10,000 - 20,000{" "}
                                      </label>
                                      <br />
                                    </div>

                                    <div className="flex items-center pt-2">
                                      <input
                                        type="checkbox"
                                        name="price_filter"
                                        checked={
                                          priceFilters.between20000and40000
                                        }
                                        onChange={() =>
                                          handlePriceFilterChange(
                                            "between20000and40000"
                                          )
                                        }
                                        className="border border-neutral-400 w-5 h-5 mb-3"
                                      />
                                      <label className="xl:text-sm text-base pl-2 mb-2">
                                        {" "}
                                        20,000 - 40,000
                                      </label>
                                      <br />
                                    </div>
                                    <div className="flex items-center pt-2">
                                      <input
                                        type="checkbox"
                                        name="price_filter"
                                        checked={
                                          priceFilters.between40000and60000
                                        }
                                        onChange={() =>
                                          handlePriceFilterChange(
                                            "between40000and60000"
                                          )
                                        }
                                        className="border border-neutral-400 w-5 h-5 mb-3"
                                      />
                                      <label className="xl:text-sm text-base pl-2 mb-2">
                                        {" "}
                                        40,000 - 60,000
                                      </label>
                                      <br />
                                    </div>

                                    <div className="flex items-center pt-2">
                                      <input
                                        type="checkbox"
                                        name="price_filter"
                                        checked={
                                          priceFilters.between60000and80000
                                        }
                                        onChange={() =>
                                          handlePriceFilterChange(
                                            "between60000and80000"
                                          )
                                        }
                                        className="border border-neutral-400 w-5 h-5 mb-3"
                                      />
                                      <label className="xl:text-sm text-base pl-2 mb-2">
                                        {" "}
                                        60,000 - 80,000
                                      </label>
                                      <br />
                                    </div>

                                    <div className="flex items-center pt-2">
                                      <input
                                        type="checkbox"
                                        name="price_filter"
                                        checked={priceFilters.above80000}
                                        onChange={() =>
                                          handlePriceFilterChange("above80000")
                                        }
                                        className="border border-neutral-400 w-5 h-5 mb-3"
                                      />
                                      <label className="xl:text-sm text-base pl-2 mb-2">
                                        {" "}
                                        Above 80,000
                                      </label>
                                      <br />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>

                          <div className="border-t"></div>

                          {/* First filter */}
                          <div
                            className={`font  relative lg:pt-6 lg:pb-4 pt-6 pb-4`}
                          >
                            <span
                              className={`absolute right-0 bottom-0 top-0 sm:bottom-auto items-center lg:mt-4 md:mt-6 mt-4 sm:-translate-y-1/2 w-10 h-10 flex items-center justify-center cursor-pointer ${
                                isOpen ? "transform -rotate-180" : ""
                              }`}
                              onClick={() => setIsOpen(!isOpen)}
                            >
                              <i className="text-xl las la-angle-down"></i>
                            </span>
                            <div className="flex  flex-col sm:flex-row sm:items-center space-y-6 sm:space-y-0">
                              <div className="font-medium text-xl md:text-lg xl:text-base !leading-[114%] lg:pb-2 lg:pt-0 lg:pb-0 md:pb-4 md:pt-4">
                                Categories
                              </div>
                            </div>
                            {isOpen && (
                              <div className="">
                                <div className="my-1 md:my-1 space-y-5">
                                  <div className="">
                                    <div className="pb-3 pt-2">
                                      <div className="grid grid-cols-2">
                                        <div className="flex items-center">
                                          <input
                                            type="checkbox"
                                            name="category_filter"
                                            checked={categoryFilters.standard}
                                            onChange={() =>
                                              handleCategoryFilterChange(
                                                "standard"
                                              )
                                            }
                                            className="border border-neutral-400 w-5 h-5 mb-3"
                                          />
                                          <label className="xl:text-sm text-base pl-2 mb-2">
                                            {" "}
                                            Standard
                                          </label>
                                          <br />
                                        </div>
                                        <div className="flex items-center">
                                          <input
                                            type="checkbox"
                                            name="category_filter"
                                            checked={categoryFilters.luxury}
                                            onChange={() =>
                                              handleCategoryFilterChange(
                                                "luxury"
                                              )
                                            }
                                            className="border border-neutral-400 w-5 h-5 mb-3"
                                          />
                                          <label className="xl:text-sm text-base pl-2 mb-2">
                                            {" "}
                                            Luxury{" "}
                                          </label>
                                          <br />
                                        </div>
                                      </div>

                                      <div className="grid grid-cols-2 pt-2">
                                        <div className="flex items-center">
                                          <input
                                            type="checkbox"
                                            name="category_filter"
                                            checked={categoryFilters.premium}
                                            onChange={() =>
                                              handleCategoryFilterChange(
                                                "premium"
                                              )
                                            }
                                            className="border border-neutral-400 w-5 h-5 mb-3"
                                          />
                                          <label className="xl:text-sm text-base pl-2 mb-2">
                                            {" "}
                                            Premium{" "}
                                          </label>
                                          <br />
                                        </div>
                                        <div className="flex items-center">
                                          <input
                                            type="checkbox"
                                            name="category_filter"
                                            checked={categoryFilters.group}
                                            onChange={() =>
                                              handleCategoryFilterChange(
                                                "group"
                                              )
                                            }
                                            className="border border-neutral-400 w-5 h-5 mb-3"
                                          />
                                          <label className="xl:text-sm text-base pl-2 mb-2">
                                            {" "}
                                            Group Tour{" "}
                                          </label>
                                          <br />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>

                          {/* Second filter */}
                          <div className="border-t"></div>
                          <div
                            className={`font  relative lg:pt-6 lg:pb-4 pt-4 pb-4`}
                          >
                            {/*  eslint-disable-next-line jsx-a11y/anchor-has-content */}
                            {/*<a href="##" className="absolute inset-0" />*/}
                            <span
                              className={`absolute right-0 bottom-0 top-0 sm:bottom-auto items-center lg:mt-2 md:mt-6 mt-2 sm:-translate-y-1/2 w-10 h-10 flex items-center justify-center cursor-pointer ${
                                onOpen ? "transform -rotate-180" : ""
                              }`}
                              onClick={() => setOnOpen(!onOpen)}
                            >
                              <i className="text-xl las la-angle-down"></i>
                            </span>
                            <div className="flex  flex-col sm:flex-row sm:items-center space-y-6 sm:space-y-0">
                              <div className="font-medium text-xl md:text-lg xl:text-base !leading-[114%] lg:pb-2 lg:pt-0 lg:pb-0 md:pb-4 md:pt-4">
                                Duration (in Days)
                              </div>
                            </div>
                            {onOpen && (
                              <div className="">
                                {/* {renderDetailTop()} */}
                                <div className="my-1 md:my-1 space-y-5 md:pl-2">
                                  <div className="pb-3 pt-2">
                                    <div className="grid grid-cols-2">
                                      <div className="flex items-center">
                                        <input
                                          type="checkbox"
                                          name="days_filter"
                                          checked={dayFilters.between1to3}
                                          onChange={() =>
                                            handleDaysFilterChange(
                                              "between1to3"
                                            )
                                          }
                                          className="border border-neutral-400 w-5 h-5 mb-3"
                                        />
                                        <label className="xl:text-sm text-base pl-2 mb-2">
                                          {" "}
                                          1 to 3
                                        </label>
                                        <br />
                                      </div>
                                      <div className="flex items-center">
                                        <input
                                          type="checkbox"
                                          name="days_filter"
                                          checked={dayFilters.between4to6}
                                          onChange={() =>
                                            handleDaysFilterChange(
                                              "between4to6"
                                            )
                                          }
                                          className="border border-neutral-400 w-5 h-5 mb-3"
                                        />
                                        <label className="xl:text-sm text-base pl-2 mb-2">
                                          {" "}
                                          4 to 6
                                        </label>
                                        <br />
                                      </div>
                                    </div>

                                    <div className="grid grid-cols-2 pt-2">
                                      <div className="flex items-center">
                                        <input
                                          type="checkbox"
                                          name="days_filter"
                                          checked={dayFilters.between7to9}
                                          onChange={() =>
                                            handleDaysFilterChange(
                                              "between7to9"
                                            )
                                          }
                                          className="border border-neutral-400 w-5 h-5 mb-3"
                                        />
                                        <label className="xl:text-sm text-base pl-2 mb-2">
                                          {" "}
                                          7 to 9
                                        </label>
                                        <br />
                                      </div>
                                      <div className="flex items-center">
                                        <input
                                          type="checkbox"
                                          name="days_filter"
                                          checked={dayFilters.between10to12}
                                          onChange={() =>
                                            handleDaysFilterChange(
                                              "between10to12"
                                            )
                                          }
                                          className="border border-neutral-400 w-5 h-5 mb-3"
                                        />
                                        <label className="xl:text-sm text-base pl-2 mb-2">
                                          {" "}
                                          10 to 12
                                        </label>
                                        <br />
                                      </div>
                                    </div>

                                    <div className="flex items-center pt-2">
                                      <input
                                        type="checkbox"
                                        name="days_filter"
                                        checked={dayFilters.above13}
                                        onChange={() =>
                                          handleDaysFilterChange("above13")
                                        }
                                        className="border border-neutral-400 w-5 h-5 mb-3"
                                      />
                                      <label className="xl:text-sm text-base pl-2 mb-2">
                                        {" "}
                                        13 or more
                                      </label>
                                      <br />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>

                          {/* Third filter */}
                          <div className="border-t"></div>
                          <div
                            className={`font  relative lg:pt-6 lg:pb-4 pt-6 pb-4`}
                          >
                            {/*  eslint-disable-next-line jsx-a11y/anchor-has-content */}
                            {/*<a href="##" className="absolute inset-0" />*/}
                            <span
                              className={`absolute right-0 bottom-0 top-0 sm:bottom-auto items-center lg:mt-2 md:mt-6 mt-4 sm:-translate-y-1/2 w-10 h-10 flex items-center justify-center cursor-pointer ${
                                onsOpen ? "transform -rotate-180" : ""
                              }`}
                              onClick={() => setOnsOpen(!onsOpen)}
                            >
                              <i className="text-xl las la-angle-down"></i>
                            </span>
                            <div className="flex flex-col sm:flex-row sm:items-center space-y-6 sm:space-y-0">
                              <div className="font-medium text-xl md:text-lg xl:text-base !leading-[114%] lg:pb-2 lg:pt-0 lg:pb-0 md:pb-4 md:pt-4">
                                Flight Details
                              </div>
                            </div>
                            {onsOpen && (
                              <div className="">
                                <div className="my-1 md:my-1 space-y-5 md:pl-2">
                                  <div className="pb-3 pt-2">
                                    <div className="flex items-center">
                                      <input
                                        type="checkbox"
                                        name="flight_details"
                                        checked={flightFilters.withFlight}
                                        onChange={() =>
                                          handleFlightsFilterChange(
                                            "withFlight"
                                          )
                                        }
                                        className="border border-neutral-400 w-5 h-5 mb-3"
                                      />
                                      <label className="xl:text-sm text-base pl-2 mb-2">
                                        {" "}
                                        With Flight
                                      </label>
                                      <br />
                                    </div>
                                    <div className="flex items-center">
                                      <input
                                        type="checkbox"
                                        name="flight_details"
                                        checked={flightFilters.withoutFlight}
                                        onChange={() =>
                                          handleFlightsFilterChange(
                                            "withoutFlight"
                                          )
                                        }
                                        className="border border-neutral-400 w-5 h-5 mb-3"
                                      />
                                      <label className="xl:text-sm text-base pl-2 mb-2">
                                        {" "}
                                        Without Flight
                                      </label>
                                      <br />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </a>

                        {/* Add more links here */}
                      </div>
                      <div className=" font text-sky-600 font-semibold text-base mt-6">
                        Filter Your Tours
                      </div>
                      <div className="grid grid-cols-2">
                        <div className="bg-sky-100 rounded w-44 mt-6 md:mb-4 mb-8">
                          <span
                            onClick={toggleSidenav}
                            className="openbtn flex text-sm "
                          >
                            <img
                              src="https://i.ibb.co/Ry07c5W/filter.png"
                              className="pl-4 w-8 text-blue-600"
                            ></img>
                            <span className="pl-2 text-sm ">
                              Filter Details
                            </span>
                          </span>
                        </div>
                        <div className="">
                          <Popover className="relative ml-0 md:ml-10">
                            {({ open, close }) => (
                              <>
                                <Popover.Button
                                  className={`${open ? "" : "text-opacity-90"}
                                  group rounded-md focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 mt-6 mb-2 border shadow ml-10 md:ml-40 w-9/12 md:w-6/12 p-2`}
                                >
                                  <div
                                    className={`text-left inline-flex items-center justify-between focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75`}
                                    role="button"
                                  >
                                    <span>Sort By </span>
                                    <ChevronDownIcon
                                      className={`${
                                        open
                                          ? "-rotate-180"
                                          : "text-opacity-70 "
                                      } ml-1 h-4 w-4 text-black group-hover:text-opacity-80 transition ease-in-out duration-150 `}
                                      aria-hidden="true"
                                    />
                                  </div>
                                </Popover.Button>
                                <Transition
                                  as={Fragment}
                                  enter="transition ease-out duration-200"
                                  enterFrom="opacity-0 translate-y-1"
                                  enterTo="opacity-100 translate-y-0"
                                  leave="transition ease-in duration-150 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75"
                                  leaveFrom="opacity-100 translate-y-0"
                                  leaveTo="opacity-0 translate-y-1 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75"
                                >
                                  <Popover.Panel className="absolute z-40 md:w-80 w-52 max-w-xs md:px-4 px-2 transform -translate-x-1/2 left-1/2 sm:px-0">
                                    <div className="overflow-hidden rounded-md border border-neutral-200 shadow-lg focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 ml-10 md:ml-40 mr-3 md:mr-0 xl:mr-0">
                                      <div className="md:p-4 xl:p-4 p-2 bg-neutral-50 dark:bg-neutral-700">
                                        <Link
                                          to="##"
                                          className="flow-root px-2 py-2 space-y-0.5 transition duration-150 ease-in-out rounded-md focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50"
                                        >
                                          <span className="flex items-center">
                                            <span className="md:text-sm text-xs font-normal text-sky-600 dark:text-neutral-400">
                                              Sort By :
                                            </span>
                                          </span>
                                          <span className="block md:text-sm text-xs py-2 ">
                                            Lowest Price
                                          </span>
                                          <span className="block md:text-sm text-xs py-2 ">
                                            Highest Price
                                          </span>
                                          <span className="block md:text-sm text-xs py-2">
                                            Best Discount (%)
                                          </span>
                                          <span className="block md:text-sm text-xs py-2">
                                            Best Savings (₹)
                                          </span>
                                        </Link>
                                      </div>
                                    </div>
                                  </Popover.Panel>
                                </Transition>
                              </>
                            )}
                          </Popover>
                        </div>
                      </div>

                      {/* Your main content here */}
                    </div>

                    {/* <div className=" xl:block lg:block md:block hidden">
                <div className="p-4 grid xl:grid-cols-3 xl:gap-2 lg:grid-cols-3 lg:gap-1 md:grid-cols-3 md:gap-2 xl:mb-2 shadow border border-neutral-200 rounded ">
                  <div className="border-r">
                    Sort By
                  </div>
                  <div className="border-r">
                    <Popover className="relative mr-4">
                      {({ open, close }) => (
                        <>
                          <Popover.Button
                            className={`${open ? "" : "text-opacity-90"}
                  group rounded-md focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75`}
                          >
                            <div className={` inline-flex items-center focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75`} role="button">
                              <span>Price</span>
                              <ChevronDownIcon
                                className={`${open ? "-rotate-180" : "text-opacity-70 "} ml-1 h-4 w-4 text-black group-hover:text-opacity-80 transition ease-in-out duration-150 `}
                                aria-hidden="true"
                              />
                            </div>
                          </Popover.Button>
                          <Transition
                            as={Fragment}
                            enter="transition ease-out duration-200"
                            enterFrom="opacity-0 translate-y-1"
                            enterTo="opacity-100 translate-y-0"
                            leave="transition ease-in duration-150 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75"
                            leaveFrom="opacity-100 translate-y-0"
                            leaveTo="opacity-0 translate-y-1 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75"
                          >
                            <Popover.Panel className="absolute z-40 w-52 max-w-xs px-4 mt-4 transform -translate-x-1/2 left-1/2 sm:px-0">
                              <div className="overflow-hidden rounded-md border border-neutral-200 shadow-lg focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75">
                                <div className="p-4 bg-neutral-50 dark:bg-neutral-700">
                                  <Link
                                    to="##"
                                    className="flow-root px-2 py-2 space-y-0.5 transition duration-150 ease-in-out rounded-md focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50"
                                  >
                                    <span className="flex items-center">
                                      <span className="text-xs font-normal text-gray-500 dark:text-neutral-400">
                                        Sort By :
                                      </span>
                                    </span>
                                    <span className="block text-sm py-2 ">
                                      Lowest Price
                                    </span>
                                    <span className="block text-sm py-2 ">
                                      Highest Price
                                    </span>
                                    <span className="block text-sm py-2">
                                      Best Discount (%)
                                    </span>
                                    <span className="block text-sm py-2">
                                      Best Savings (₹)
                                    </span>
                                  </Link>
                                </div>
                              </div>
                            </Popover.Panel>
                          </Transition>
                        </>
                      )}
                    </Popover>
                  </div>
                  <div className="">
                    <Popover className="relative mr-4">
                      {({ open, close }) => (
                        <>
                          <Popover.Button
                            className={`${open ? "" : "text-opacity-90"}
                  group rounded-md focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75`}
                          >
                            <div className={` inline-flex items-center `} role="button">
                              <span>Guest Rating</span>
                              <ChevronDownIcon
                                className={`${open ? "-rotate-180" : "text-opacity-70 "}
                    ml-1 h-4 w-4 text-black group-hover:text-opacity-80 transition ease-in-out duration-150 `}
                                aria-hidden="true"
                              />
                            </div>
                          </Popover.Button>
                          <Transition
                            as={Fragment}
                            enter="transition ease-out duration-200"
                            enterFrom="opacity-0 translate-y-1"
                            enterTo="opacity-100 translate-y-0"
                            leave="transition ease-in duration-150"
                            leaveFrom="opacity-100 translate-y-0"
                            leaveTo="opacity-0 translate-y-1"
                          >
                            <Popover.Panel className="absolute z-40 w-52 max-w-xs px-4 mt-4 transform -translate-x-1/2 left-1/2 sm:px-0">
                              <div className="overflow-hidden rounded-2xl shadow-lg focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75">

                                {/* FOOTER 
                                <div className="p-4 bg-neutral-50 dark:bg-neutral-700">
                                  <Link
                                    to="##"
                                    className="flow-root px-2 py-2 space-y-0.5 transition duration-150 ease-in-out rounded-md focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50"
                                  >
                                    <span className="flex items-center">
                                      <span className="text-xs font-normal text-gray-500 dark:text-neutral-400">
                                        Highest Rated By :
                                      </span>
                                    </span>
                                    <span className="block flex items-center text-sm py-2 ">
                                      <i className="las la-globe"></i>
                                      <p className="pl-2">All Travellers</p>
                                    </span>
                                    <span className="block flex items-center text-sm py-2 ">
                                      <i className="las la-globe"></i>
                                      <p className="pl-2">Solo Travellers</p>
                                    </span>
                                    <span className="block flex items-center text-sm py-2 ">
                                      <i className="las la-globe"></i>
                                      <p className="pl-2">Business Travellers</p>
                                    </span>
                                    <span className="block flex items-center text-sm py-2 ">
                                      <i className="las la-globe"></i>
                                      <p className="pl-2">Family Travellers</p>
                                    </span> <span className="block flex items-center text-sm py-2 ">
                                      <i className="las la-globe"></i>
                                      <p className="pl-2">Couple Travellers</p>
                                    </span>
                                  </Link>
                                </div>
                              </div>
                            </Popover.Panel>
                          </Transition>
                        </>
                      )}
                    </Popover>
                  </div>
                <div className="border-r">
                    <Popover className="relative mr-4">
                      {({ open, close }) => (
                        <>
                          <Popover.Button
                            className={`${open ? "" : "text-opacity-90"}
                  group rounded-md focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75`}
                          >
                            <div className={` inline-flex items-center `} role="button">
                              <span>Distance</span>
                              <ChevronDownIcon
                                className={`${open ? "-rotate-180" : "text-opacity-70 "}
                    ml-1 h-4 w-4 text-black group-hover:text-opacity-80 transition ease-in-out duration-150 `}
                                aria-hidden="true"
                              />
                            </div>
                          </Popover.Button>
                          <Transition
                            as={Fragment}
                            enter="transition ease-out duration-200"
                            enterFrom="opacity-0 translate-y-1"
                            enterTo="opacity-100 translate-y-0"
                            leave="transition ease-in duration-150"
                            leaveFrom="opacity-100 translate-y-0"
                            leaveTo="opacity-0 translate-y-1"
                          >
                            <Popover.Panel className="absolute z-40 w-screen max-w-xs px-4 mt-4 transform -translate-x-1/2 left-1/2 sm:px-0">
                              <div className="overflow-hidden rounded-2xl shadow-lg ring-1 ring-black ring-opacity-5">

                                {/* FOOTER 
                                <div className="p-4 bg-neutral-50 dark:bg-neutral-700">
                                  <Link
                                    to="##"
                                    className="flow-root px-2 py-2 space-y-0.5 transition duration-150 ease-in-out rounded-md focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50"
                                  >
                                    <span className="flex items-center">
                                      <span className="text-sm font-medium ">
                                        Documentation
                                      </span>
                                    </span>
                                    <span className="block text-sm text-gray-500 dark:text-neutral-400">
                                      Start integrating products and tools
                                    </span>
                                  </Link>
                                </div>
                              </div>
                            </Popover.Panel>
                          </Transition>
                        </>
                      )}
                    </Popover>
                  </div> */}
                    {/* <div className="">
                    Stars <i className="las la-sort-amount-up-alt"></i>
                  </div> 
                </div>
              </div> */}

                    {/* <div className="xl:mt-0 lg:mt-0 md:mt-3 p-3 flex items-center bg-neutral-50 border border-neutral-200 mb-3 shadow rounded justify-between">
                <div className="xl:block lg:block md:block hidden">
                  <div className="text-base flex items-center font-medium pl-2 ">
                    <img src="https://i.ibb.co/ySY5BFQ/pricetag.webp" alt="" className="w-8 pr-2" />
                    Simply <span className="text-green-600 px-1 font-semibold "> login </span> right now to unlock member rates and save up to 50%!
                  </div>
                </div>
                <div className="text-base flex items-center font-medium pl-2 xl:hidden lg:hidden md:hidden block">
                  <img src="https://i.ibb.co/ySY5BFQ/pricetag.webp" alt="" className="w-8 pr-2" />
                  Simply <span className="text-green-600 px-1 font-semibold "> login </span>
                </div>
                <button className="bg-green-600 rounded-md text-white text-xs px-6 py-2 uppercase">
                  Login
                </button>
              </div> */}

                    {/* <div style={{ height: "950px", overflowY: "auto" }}> */}
                    {filteredPackages
                      .filter((item: any) =>
                        item.tour_city
                          .toLowerCase()
                          .includes(locationText.toLocaleLowerCase())
                      )
                      .map((item: any, index: any) => {
                        return (
                          // <div
                          //   className="border rounded-lg my-2 xl:flex lg:flex md:flex sm:flex"
                          //   key={index}
                          // >
                          <div
                            className="border rounded-lg mb-3 xl:flex lg:flex md:flex sm:flex"
                            style={{ maxWidth: "900px", overflowX: "auto" }}
                            key={index}
                          >
                            <div className="flex-shrink-0 w-full sm:w-64 p-4">
                              <Link
                                to={"/" + item.slug}
                                className={`block aspect-w-3 aspect-h-3`}
                              >
                                <NcImage
                                  src={
                                    item.thumbnail_image
                                      ? `https://admin.trawelmart.com/admin/public/${item.thumbnail_image}`
                                      : `https://admin.trawelmart.com/admin/public/${
                                          item.photos_details.split(", ")[0]
                                        }`
                                  }
                                  // className="rounded-t-md xl:h-64 md:h-64 h-88 md:pl-4 md:pt-4 md:pb-4 xl:pt-4 xl:pl-4 xl:pb-4"
                                  alt={item.tour_title}
                                />
                              </Link>

                              {/*   {saleOff && (
                                  <SaleOffBadge className="absolute left-5 top-5 !bg-orange-500" />
                                  )} */}
                            </div>

                            <div className="flex flex-wrap p-3 space-y-3 w-full">
                              <div className="lg:w-8/12 md:w-8/12 sm:w-full space-y-2 pt-2">
                                <div className="xl:text-lg lg:text-base font-semibold">
                                  {item.tour_title}
                                </div>
                                <div className="items-center">
                                  <span className="las la-star checked"></span>
                                  <span className="las la-star checked"></span>
                                  <span className="las la-star checked"></span>
                                  <span className="las la-star checked"></span>
                                  <span className="las la-star checked"></span>
                                  <span className="pl-2 text-xs">
                                    {item.tour_city}
                                  </span>
                                </div>

                                <div className="flex items-center">
                                  {/* <Badge name={item.visited_people_count} className="bg-sky-100 text-sky-800"></Badge> */}
                                  <p className="text-semibold text-xs ">
                                    Guest Travelled
                                  </p>
                                  {/* <p className="font-normal text-xs pl-2">
                                    ({item.visited_people_count} reviews)
                                   </p> */}
                                  <p className="font-normal text-xs pl-2">
                                    ({item.visited_people_count})
                                  </p>
                                  <Badge
                                    name={item.tour_type}
                                    className="bg-sky-100 text-sky-800 ml-2"
                                  ></Badge>
                                  {/* <p className="font-normal text-xs pl-2 text-sky-600 font-semibold">
                                   {item.tour_type}
                                   </p> */}
                                </div>
                                <nav
                                  className="pt-3  lg:pb-2 nc-SocialsList flex space-x-12 text-2xl text-neutral-6000 dark:text-neutral-300 !space-x-2"
                                  data-nc-id="SocialsList"
                                >
                                  <div className="">
                                    <a
                                      className="flex items-center justify-center w-8 h-8 rounded-full bg-neutral-100 dark:bg-neutral-800 text-xl"
                                      href="#"
                                      target="_blank"
                                      rel="noopener noreferrer"
                                      title=""
                                    >
                                      <i className="las la-building"></i>
                                    </a>
                                  </div>
                                  <div className="">
                                    <a
                                      className="flex items-center justify-center w-8 h-8 rounded-full bg-neutral-100 dark:bg-neutral-800 text-xl"
                                      href="#"
                                      target="_blank"
                                      rel="noopener noreferrer"
                                      title=""
                                    >
                                      <i className="las la-utensils"></i>
                                    </a>
                                  </div>
                                  <div className="">
                                    <a
                                      className="flex items-center justify-center w-8 h-8 rounded-full bg-neutral-100 dark:bg-neutral-800 text-xl"
                                      href="#"
                                      target="_blank"
                                      rel="noopener noreferrer"
                                      title=""
                                    >
                                      <i className="las la-car"></i>
                                    </a>
                                  </div>
                                  <div className="items-center">
                                    <a
                                      className="flex items-center justify-center w-8 h-8 rounded-full bg-neutral-100 dark:bg-neutral-800 text-xl"
                                      href="#"
                                      target="_blank"
                                      rel="noopener noreferrer"
                                      title=""
                                    >
                                      <i className="las la-binoculars"></i>
                                    </a>
                                  </div>
                                  {(item as any).is_flight === "Yes" && (
                                    <span className="text-xs text-sky-600 pt-2 ml-2 border-neutral-500 pl-3">
                                      <i className="las la-plane text-base"></i>{" "}
                                      With Flight{" "}
                                    </span>
                                  )}
                                </nav>

                                <div className="border-t xl:mr-2 lg:mr-2 md:mr-2" />
                                <div className="xl:flex xl:justify-between items-center ">
                                  <div className="flex item-center justify-betweeen">
                                    {/* <i className="text-base text-green-800 font-normal las la-map-marker" /> */}
                                    <div className="text-xs  ">
                                      {(item as any).night_description && (
                                        <div className="card">
                                          <p className="font text-xs font-normal">
                                            {
                                              (
                                                item as any
                                              ).night_description.split(", ")[0]
                                            }{" "}
                                            {(
                                              item as any
                                            ).night_description.split(
                                              ", "
                                            )[1] &&
                                              ", " +
                                                (
                                                  item as any
                                                ).night_description.split(
                                                  ", "
                                                )[1]}
                                            <span>
                                              {showMoreStates[
                                                (item as any).id
                                              ] && (
                                                <p className="font text-xs font-normal">
                                                  {(
                                                    item as any
                                                  ).night_description
                                                    .split(", ")
                                                    .slice(2)
                                                    .map(
                                                      (night: any, i: any) => (
                                                        <span key={i}>
                                                          {night}
                                                          {(
                                                            item as any
                                                          ).night_description.split(
                                                            ", "
                                                          ).length ===
                                                          i + 3
                                                            ? " "
                                                            : ", "}
                                                        </span>
                                                      )
                                                    )}
                                                </p>
                                              )}
                                            </span>
                                            {(
                                              item as any
                                            ).night_description.split(", ")
                                              .length > 2 && (
                                              <span>
                                                <button
                                                  onClick={() =>
                                                    toggleReadMoreLess(
                                                      (item as any).id
                                                    )
                                                  }
                                                  className="font text-xs font-medium"
                                                >
                                                  {showMoreStates[
                                                    (item as any).id
                                                  ]
                                                    ? "Show Less"
                                                    : `+${
                                                        ((
                                                          item as any
                                                        ).night_description?.split(
                                                          ", "
                                                        ).length || 0) - 2
                                                      } more`}
                                                </button>
                                              </span>
                                            )}
                                          </p>
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                  {/* <Badge name={index} className="mr-4 text-xs text-sky-700 bg-sky-100 xl:block lg:hidden md:hidden hidden px-4" /> */}
                                </div>
                                <div className="border-t mr-2" />
                                <div className="text-xs xl:block lg:block md:block block sm:hidden">
                                  Grab best price with exclusive Tours and
                                  Packages
                                </div>
                                <div className="xl:hidden lg:hidden md:hidden hidden sm:block">
                                  <div className="grid grid-cols-2 gap-2 ">
                                    <div className="text-2xl text-neutral-800 font-bold ">
                                      {(item as any).price}
                                    </div>
                                    <p
                                      className="text-xs font-semibold underline  text-red-500 lg:pb-2 cursor-pointer"
                                      onClick={iropenModal}
                                    >
                                      Price Breakup
                                    </p>
                                    <a href={"/" + (item as any).slug}>
                                      <button className="w-10/12 gradient2 text-white ml-2 py-2 lg:mb-2 text-sm rounded-md">
                                        View Details
                                      </button>
                                    </a>
                                  </div>
                                </div>
                              </div>
                              <div className="xl:block lg:block md:block hidden lg:w-4/12 md:w-4/12 sm:w-12/12 xl:border-l-2 lg:border-l-2 md:border-l-2 xl:border-dashed lg:border-dashed md:border-dashed text-right pt-8 justify-end space-y-0.5">
                                {/* <div className="">
                                <Logo className="w-16" />
                                  </div> */}
                                <div className="uppercase text-xs text-green-500">
                                  Super Deal Price
                                </div>
                                <p className="text-xs font-normal pt-1 text-neutral-500">
                                  Starts from
                                </p>
                                <div className="text-2xl text-neutral-800 font-bold ">
                                  {formatPrice((item as any).price)}
                                </div>
                                <p
                                  className="text-sm font-semibold underline text-red-500 lg:pb-2 cursor-pointer"
                                  onClick={() => iropenModal((item as any).id)}
                                >
                                  Price Breakup
                                </p>
                                <a href={"/" + (item as any).slug}>
                                  <button className="xl:w-7/12 md:w-9/12 gradient2 text-white mt-2 ml-2 py-2 lg:mb-2 text-sm rounded-md">
                                    View Details
                                  </button>
                                </a>
                                <Transition
                                  appear
                                  show={selectedItemId == (item as any).id}
                                  as={Fragment}
                                >
                                  <Dialog
                                    as="div"
                                    className="relative z-10"
                                    onClose={ircloseModal}
                                  >
                                    <Transition.Child
                                      as={Fragment}
                                      enter="ease-out duration-300"
                                      enterFrom="opacity-0"
                                      enterTo="opacity-100"
                                      leave="ease-in duration-200"
                                      leaveFrom="opacity-100"
                                      leaveTo="opacity-0"
                                    >
                                      <div className="fixed inset-0  bg-opacity-25" />
                                    </Transition.Child>
                                    <div className="font fixed inset-0 overflow-y-auto">
                                      <div className="flex min-h-full items-center justify-center p-4 text-center">
                                        <Transition.Child
                                          as={Fragment}
                                          enter="ease-out duration-300"
                                          enterFrom="opacity-0 scale-95"
                                          enterTo="opacity-100 scale-100"
                                          leave="ease-in duration-200"
                                          leaveFrom="opacity-100 scale-100"
                                          leaveTo="opacity-0 scale-95"
                                        >
                                          <Dialog.Panel className="w-full max-w-lg transform overflow-hidden rounded-2xl bg-white text-center align-middle transition-all">
                                            <div className="text-right mt-2 mr-2">
                                              <button
                                                type="button"
                                                className="inline-flex justify-center rounded-full border border-transparent bg-blue-100 px-2 py-1 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                                                onClick={ircloseModal}
                                              >
                                                X
                                              </button>
                                            </div>
                                            <Dialog.Title
                                              as="h3"
                                              className="font p-4 bg-[#fbf7fb] lg:-mt-12 md:-mt-9 -mt-12 lg:pt-6 text-base font-regular text-left leading-6 text-sky-600"
                                            >
                                              Trawel Mart's Detailed Tour Price
                                              <p className="xl:text-xs font font-regular text-gray-500">
                                                Prices & discounts are Per
                                                Person for Indian Nationals
                                                only.
                                              </p>
                                            </Dialog.Title>
                                            <div className="p-4">
                                              <table
                                                key="table"
                                                className="table"
                                              >
                                                <table
                                                  className="rounded-2xl"
                                                  style={{ width: "480px" }}
                                                >
                                                  <thead>
                                                    <tr>
                                                      <th
                                                        style={{
                                                          border:
                                                            "1px solid #d9d7d7",
                                                          padding: "10px",
                                                          fontSize: "13px",
                                                          fontWeight: "500",
                                                          textAlign: "left",
                                                          width: "230px",
                                                        }}
                                                      >
                                                        Premium Packages
                                                      </th>
                                                      <th
                                                        style={{
                                                          border:
                                                            "1px solid #d9d7d7",
                                                          padding: "10px",
                                                          fontSize: "13px",
                                                          fontWeight: "500",
                                                          textAlign: "center",
                                                        }}
                                                      >
                                                        Price
                                                      </th>
                                                    </tr>
                                                  </thead>
                                                  <tbody>
                                                    <tr>
                                                      <td
                                                        style={{
                                                          border:
                                                            "1px solid #d9d7d7",
                                                          padding: "10px",
                                                          fontSize: "13px",
                                                          fontWeight: "400",
                                                          textAlign: "left",
                                                          backgroundColor:
                                                            "#f9f9f9",
                                                          width: "230px",
                                                        }}
                                                      >
                                                        Adult
                                                      </td>
                                                      <td
                                                        style={{
                                                          border:
                                                            "1px solid #d9d7d7",
                                                          padding: "10px",
                                                          fontWeight: "500",
                                                          fontSize: "14px",
                                                          textAlign: "center",
                                                          backgroundColor:
                                                            "#f9f9f9",
                                                        }}
                                                      >
                                                        {" "}
                                                        {pricingData
                                                          ? formatPrice(
                                                              (
                                                                pricingData as any
                                                              ).adult
                                                            )
                                                          : "0"}
                                                      </td>
                                                    </tr>
                                                    <tr>
                                                      <td
                                                        style={{
                                                          border:
                                                            "1px solid #d9d7d7",
                                                          padding: "10px",
                                                          fontSize: "13px",
                                                          fontWeight: "400",
                                                          textAlign: "left",
                                                          width: "210px",
                                                        }}
                                                      >
                                                        Child With Bed
                                                      </td>
                                                      <td
                                                        style={{
                                                          border:
                                                            "1px solid #d9d7d7",
                                                          padding: "10px",
                                                          fontWeight: "500",
                                                          fontSize: "14px",
                                                          textAlign: "center",
                                                        }}
                                                      >
                                                        {" "}
                                                        {pricingData
                                                          ? formatPrice(
                                                              (
                                                                pricingData as any
                                                              ).child
                                                            )
                                                          : "0"}
                                                      </td>
                                                    </tr>

                                                    <tr>
                                                      <td
                                                        style={{
                                                          border:
                                                            "1px solid #d9d7d7",
                                                          padding: "10px",
                                                          fontSize: "13px",
                                                          fontWeight: "400",
                                                          textAlign: "left",
                                                          backgroundColor:
                                                            "#f9f9f9",
                                                          width: "230px",
                                                        }}
                                                      >
                                                        Child Without Bed
                                                      </td>
                                                      <td
                                                        style={{
                                                          border:
                                                            "1px solid #d9d7d7",
                                                          padding: "10px",
                                                          fontWeight: "500",
                                                          fontSize: "14px",
                                                          textAlign: "center",
                                                          backgroundColor:
                                                            "#f9f9f9",
                                                        }}
                                                      >
                                                        {" "}
                                                        {pricingData
                                                          ? formatPrice(
                                                              (
                                                                pricingData as any
                                                              ).infant
                                                            )
                                                          : "0"}
                                                      </td>
                                                    </tr>
                                                  </tbody>
                                                </table>
                                              </table>
                                            </div>
                                            {/* <div className="text-left font font-medium text-sm xl:ml-4">
                                                Exclusion
                                              </div>
                                              <ul className="list-disc text-left xl:text-xs list-inside xl:ml-4 pb-6">
                                                <li className="list-disc list-inside py-1">
                                                  Air Ticket
                                                </li>
                                                <li className="list-disc list-inside py-1">
                                                  Visa
                                                </li>
                                                <li className="list-disc list-inside py-1">
                                                  GST 5% , TCS 5%
                                                </li>
                                              </ul> */}
                                          </Dialog.Panel>
                                        </Transition.Child>
                                      </div>
                                    </div>
                                  </Dialog>
                                </Transition>
                              </div>
                            </div>
                            <div className="grid grid-cols-2 gap-2 items-center p-4 xl:hidden lg:hidden md:hidden block sm:hidden">
                              <div className="">
                                <div className="uppercase text-xs text-green-500">
                                  Super Deal Price
                                </div>
                                <p className="text-xs font-normal pt-1 text-neutral-500">
                                  Starts from
                                </p>
                                <div className="text-2xl text-neutral-800 font-bold ">
                                  {formatPrice((item as any).price)}
                                </div>
                              </div>
                              <a href={"/" + (item as any).slug}>
                                <button className="w-10/12 gradient2 text-white mt-6 ml-7 py-2 lg:mb-2 text-sm rounded-md">
                                  View Details
                                </button>
                              </a>
                            </div>
                          </div>
                        );
                      })}
                    {/* </div> */}
                  </div>
                </div>
              </div>

              {/* <div className="flex mt-16 justify-center items-center">
            <Pagination />
          </div> */}
            </div>
            {/* <SectionGridFilterCard className="py-24 lg:pb-28 lg:pt-0" /> */}
          </div>
        </>
      ) : (
        <>
          {isLoading ? (
            <>
              <div className=" p-4 justify-center align-center items-center text-center">
                <Skeleton height={60} width={"50%"} />
                <Skeleton />
                <Skeleton height={12} />
                <Skeleton height={12} count={2} />
                <Skeleton height={10} />
              </div>
            </>
          ) : (
            <>
              <div className="container text-center justify-center align-center">
                <div className="justify-center align-center items-center text-center">
                  <div className="p-4 justify-center align-center textcenter">
                    <img
                      src={tmanim}
                      alt=""
                      className="xl:w-3/12 lg:w-3/12 md:w-3/12 w-6/12"
                    />
                  </div>
                  <h2 className="font-bold xl:text-3xl lg:text-3xl md:text-3xl text-xl -mt-6 font">
                    Packages Coming Soon!
                  </h2>
                  <p className="py-3 font1 font-regular xl:text-base lg:text-base text-sm">
                    Currently, there are no package(s) found matching your
                    holiday request.
                  </p>
                  <p className=" font1 font-regular xl:text-base lg:text-base text-sm xl:px-56">
                    Please try exploring our other available options by checking
                    out trending destinations. Our dedicated Travel Experts are
                    on hand to offer you an expert guidance and support
                    throughout the entirety of your trip.
                  </p>
                  <p className=" font1 font-regular xl:text-base lg:text-base text-sm py-4">
                    Reach out to us{" "}
                    <span className="text-sky-600">
                      {" "}
                      <a href="tel:080 4642 7999">080 4642 7999</a>
                    </span>{" "}
                    or drop an Email at{" "}
                    <span className="text-sky-600">
                      <a href="mailto:enquiry@trawelmart.com">
                        enquiry@trawelmart.com
                      </a>
                    </span>
                  </p>
                </div>
                {packages.filter(
                  (pkg) =>
                    (pkg as any).tour_city
                      .toLowerCase()
                      .includes(locationText.toLowerCase()) ||
                    (pkg as any).tour_title
                      .toLowerCase()
                      .includes(locationText.toLowerCase())
                ).length == 0 && (
                  <a href="/">
                    <button className="px-1 w-32  gradient2 text-white ml-2 py-2 lg:mb-32 mb-32 text-sm rounded-md">
                      Go Home
                    </button>
                  </a>
                )}
                {locationFiltered.length > 0 &&
                  filteredPackages.length == 0 && (
                    <a href="#!" onClick={resetFilters}>
                      <button className="px-1 w-32  gradient2 text-white ml-2 py-2 lg:mb-32 mb-32 text-sm rounded-md">
                        Clear Filters
                      </button>
                    </a>
                  )}
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default ListingRealEstatePage;
